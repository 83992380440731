import React, { useMemo } from 'react'
import { makeStyles } from '@material-ui/styles'
import Theme from 'core/themes/model'
import Card from 'core/elements/card'
import { getFieldsForCard } from 'core/components/InfoPanel'
import InfoCard from 'k8s/components/common/entity/info-card'
import Progress from 'core/components/progress/Progress'
import { renderAgeFromTimestamp } from 'k8s/components/common/entity/helpers'
import Grid from 'core/elements/grid'
import { listPersistentVolumeClaims } from 'k8s/components/storage/persistent-volume-claims/new-actions'
import { makePersistentVolumeClaimSelector } from 'k8s/components/storage/persistent-volume-claims/selectors'
import { listDataVolumes } from 'k8s/components/storage/data-volumes/new-actions'
import { dataVolumesSelector } from 'k8s/components/storage/data-volumes/selectors'
import useListAction from 'core/hooks/useListAction'
import useSelectorWithParams from 'core/hooks/useSelectorWithParams'
import { getVmiStatus } from '../VmOverviewPage'
import Badge from 'core/elements/badge/Badge'
import { getDiskColumns, getDisks, IDisk } from '../vm-details/helpers'
import InfoBlocks from 'core/components/entity-page/InfoBlocks'
import ResourceInfo from '../vm-details/ResourceInfo'
import Text from 'core/elements/Text'
import { IVirtualMachineDetailsPageTabs } from '../model'
import SimpleLink from 'core/components/SimpleLink'
import { routes } from 'core/utils/routes'
import EntityEventsPage from 'k8s/components/common/entity/entity-events-page'
import LabelsAndAnnotationsSection from 'k8s/components/common/entity/labels-and-annotations/LabelsAndAnnotationsSection'
import CardHeaderWithLink from 'core/elements/card/CardHeaderWithLink'
import CardRefreshButton from '../vm-details/CardRefreshButton'
import GridDefaultCell from 'core/elements/grid/cells/GridDefaultCell'
import { listNetworks } from 'k8s/components/networking/network/actions'
import { networksSelector } from 'k8s/components/networking/network/selectors'
import { INetworkAttachmentDefinitionsDetailsPageTabs } from 'k8s/components/networking/network/model'
import { storageClassSelector } from 'k8s/components/storage/storage-classes/selectors'
import { listStorageClasses } from 'k8s/components/storage/storage-classes/new-actions'

function PrimaryNetworkGridCell() {
  return (
    <GridDefaultCell>
      Pod Network <b>(primary)</b>
    </GridDefaultCell>
  )
}

const useStyles = makeStyles((theme: Theme) => ({
  overview: {
    display: 'grid',
    gridTemplateColumns: '633px 1fr',
    marginTop: '16px',
    gridGap: '24px',
  },
  column: {
    display: 'grid',
    gridGap: '24px',
    gridAutoFlow: 'row',
    gridAutoRows: 'max-content',
  },
  metadataTable: {
    borderSpacing: '20px',
    'last-child': {
      width: '100%',
    },
  },
  customCardHeader: {
    display: 'flex',
    padding: '16px 32px 0px',
    gap: 8,
    alignItems: 'center',
  },
  borderTop: {
    borderTop: `1px solid ${theme.components.table.border}`,
  },
}))

const pvcSelector = makePersistentVolumeClaimSelector()

export const renderPhase = (status, vmi) => {
  if (!status) return ''
  const { variant } = getVmiStatus(status, vmi?.paused)
  return <Badge variant={variant} text={vmi?.paused ? 'Paused' : status} />
}

const renderVmiReady = (conditions) => {
  const readyCondition = conditions.find((cond) => cond.type === 'Ready')
  return readyCondition?.status
}

// Sadly could not import the one from vm overview page due to ts errors
export const getNetworkColumns = ({
  clusterId,
  networkIdsByName,
}: {
  clusterId: string
  networkIdsByName: { [key: string]: string }
}) => [
  {
    key: 'networkName',
    label: 'Network',
    render: (value: string) =>
      value === 'Pod Network' ? (
        <PrimaryNetworkGridCell />
      ) : (
        <SimpleLink
          lineClamp={3}
          src={routes.kubevirtNetworking.networkDetails.path({
            clusterId,
            id: networkIdsByName?.[value],
            tab: INetworkAttachmentDefinitionsDetailsPageTabs.Overview,
          })}
        >
          {value}
        </SimpleLink>
      ),
  },
  { key: 'ipAddress', label: 'IP Address', formatFn: (value: string) => (value ? value : 'N/A') },
  { key: 'mac', label: 'MAC Address', formatFn: (value: string) => (value ? value : 'N/A') },
]

const metadataFields = [
  {
    id: 'status.conditions',
    title: 'Ready',
    render: renderVmiReady,
  },
  {
    id: 'status.phase',
    title: 'VMI Phase',
    render: renderPhase,
  },
  {
    id: 'created',
    title: 'Created',
    required: true,
    render: renderAgeFromTimestamp,
  },
]

export default function Overview({ vmi, loading }) {
  const classes = useStyles()
  const params = useMemo(
    () => ({
      clusterId: vmi?.clusterId,
      namespace: vmi?.namespace,
    }),
    [vmi],
  )

  const { loading: loadingDataVolumes, reload: reloadDataVolumes } = useListAction(
    listDataVolumes,
    {
      params,
      requiredParams: ['clusterId'],
    },
  )
  const dataVolumes = useSelectorWithParams(dataVolumesSelector, params)

  const { loading: loadingPvcs, reload: reloadPvcs } = useListAction(listPersistentVolumeClaims, {
    params,
    requiredParams: ['clusterId'],
  })
  const pvcs = useSelectorWithParams(pvcSelector, params)

  const { loading: loadingStorageClasses, reload: reloadStorageClasses } = useListAction(
    listStorageClasses,
    {
      params,
      requiredParams: ['clusterId'],
    },
  )
  const storageClasses = useSelectorWithParams(storageClassSelector, params)

  const storageClassIdsByName = useMemo(() => {
    return storageClasses.reduce((accum, sc) => {
      return {
        ...accum,
        [sc?.name]: sc?.id,
      }
    }, {})
  }, [storageClasses])

  const { loading: loadingNetworks, reload: reloadNetworks } = useListAction(listNetworks, {
    params,
    requiredParams: ['clusterId', 'namespace'],
  })
  const networks = useSelectorWithParams(networksSelector, params)

  const networkIdsByName = useMemo(() => {
    return networks.reduce((accum, network) => {
      return {
        ...accum,
        [network?.name]: network?.id,
      }
    }, {})
  }, [networks])

  const diskColumns = getDiskColumns({ storageClassIdsByName })
  const networkColumns = getNetworkColumns({ clusterId: vmi?.clusterId, networkIdsByName })

  const metadata = useMemo(() => {
    return getFieldsForCard(metadataFields, vmi)
  }, [vmi])

  const disks = useMemo(() => getDisks({ entity: vmi, dataVolumes, pvcs }), [
    vmi,
    dataVolumes,
    pvcs,
  ])

  const entities = useMemo(() => {
    const pods =
      vmi?.podIds?.map((id) => ({
        id,
      })) || []
    return [vmi, ...pods].filter((entity) => !!entity)
  }, [vmi])

  const overviewData = useMemo(
    () => [
      {
        label: 'Node',
        value: vmi?.status?.nodeName,
      },
      {
        label: 'Cluster',
        value: vmi?.clusterName,
      },
      {
        label: 'Namespace',
        value: vmi?.namespace,
      },
      {
        label: 'VM',
        customValue: (
          <SimpleLink
            textVariant="caption1"
            src={
              vmi?.vmOwner?.name &&
              routes.virtualMachines.details.path({
                clusterId: vmi?.clusterId,
                id: vmi?.vmOwner?.uid,
                tab: IVirtualMachineDetailsPageTabs.Overview,
              })
            }
          >
            {vmi?.vmOwner?.name}
          </SimpleLink>
        ),
      },
    ],
    [vmi],
  )

  return (
    <Progress loading={loading}>
      <div className={classes.overview}>
        <div className={classes.column}>
          <Card
            title={
              <div className={classes.customCardHeader}>
                <Text variant="subtitle2">{vmi.name}</Text>
                {renderPhase(vmi?.status?.phase, vmi)}
              </div>
            }
          >
            <Text variant="body2">Virtual Machine Instance</Text>
            <InfoBlocks data={overviewData} />
          </Card>
          <InfoCard
            items={metadata}
            title="Properties"
            footer={<LabelsAndAnnotationsSection entity={vmi} resourceType="vmi" />}
          />
          <Card
            title={
              <CardHeaderWithLink
                linkComponent={
                  <CardRefreshButton
                    onRefresh={() => {
                      reloadNetworks(true, true)
                    }}
                  />
                }
              >
                Networks
              </CardHeaderWithLink>
            }
            withCustomBody
          >
            <Grid
              uniqueIdentifier="networkName"
              data={vmi?.networks || []}
              columns={networkColumns}
              loading={loading || loadingNetworks}
              compact
              disableToolbar
            />
          </Card>
        </div>
        <div className={classes.column}>
          <Card title="Resources" withCustomBody>
            <ResourceInfo type="CPU" fields={vmi?.cpu} />
            <ResourceInfo className={classes.borderTop} type="Memory" fields={vmi?.memory} />
          </Card>
          <EntityEventsPage entity={entities} loading={loading} noMargin />
          <Card
            title={
              <CardHeaderWithLink
                linkComponent={
                  <CardRefreshButton
                    onRefresh={() => {
                      reloadDataVolumes(true, true)
                      reloadPvcs(true, true)
                      reloadStorageClasses(true, true)
                    }}
                  />
                }
              >
                Disks
              </CardHeaderWithLink>
            }
            withCustomBody
          >
            <Grid<IDisk>
              uniqueIdentifier="name"
              data={disks}
              columns={diskColumns}
              loading={loadingDataVolumes || loadingPvcs || loadingStorageClasses}
              compact
              disableToolbar
            />
          </Card>
        </div>
      </div>
    </Progress>
  )
}
