import { routes } from 'core/utils/routes'
import DataKeys from 'k8s/DataKeys'
import { both, pick, uniq } from 'ramda'
import React, { useState, useMemo } from 'react'
import { UserPreferences, listTablePrefs, TablePrefsParams } from 'app/constants'
import { isDeccoEnv } from 'core/utils/helpers'
import DocumentMeta from 'core/components/DocumentMeta'
import ListContainer from 'core/containers/ListContainer'
import useListAction from 'core/hooks/useListAction'
import useSelectorWithParams from 'core/hooks/useSelectorWithParams'
import { ArrayElement } from 'core/actions/Action'
import { GridViewColumn } from 'core/elements/grid/Grid'
import { GridBatchActionSpec } from 'core/elements/grid/hooks/useGridSelectableRows'
import ClusterDeleteDialog from 'app/plugins/infrastructure/components/clusters/ClusterDeleteDialog'
import {
  canDeleteCluster,
  canScaleMasters,
  canScaleWorkers,
  canUpgradeCluster,
  canForceScaleWorkers,
  canForceScaleMasters,
} from 'app/plugins/infrastructure/components/clusters/helpers'
import SetAsDefaultDialog from 'app/plugins/infrastructure/components/clusters/SetAsDefaultDialog'
import ForceScaleClusterDialog from 'app/plugins/infrastructure/components/clusters/ForceScaleClusterDialog'
import { listClusters } from 'app/plugins/infrastructure/components/clusters/newActions'
import {
  clustersSelector,
  makeParamsClustersSelector,
} from 'app/plugins/infrastructure/components/clusters/selectors'
import UUIDCell from 'app/plugins/infrastructure/components/common/cells/UUIDCell'
import StatsCell from 'app/plugins/infrastructure/components/common/cells/StatsCell'
import ClusterNameCell from 'app/plugins/infrastructure/components/clusters/cluster-cells/ClusterNameCell'
import K8sVersionCell from 'app/plugins/infrastructure/components/clusters/cluster-cells/K8sVersionCell'
import { createUsePrefParamsHook } from 'core/hooks/useParams'
import { createGridStatusCell } from 'core/elements/grid/cells/GridStatusCell'
import { isAdmin } from 'app/plugins/infrastructure/components/common/helpers'
import InferActionParams from 'core/actions/InferActionParams'
import InfrastructureTypeCell from 'app/plugins/infrastructure/components/clusters/cluster-cells/InfrastructureTypeCell'
import PollingData from 'core/components/PollingData'
import { GridRowMenuItemSpec } from 'core/elements/grid/hooks/useGridRowMenu'
import KubeconfigDownloadModal from 'app/plugins/infrastructure/components/clusters/KubeconfigDownloadModal'
import useReactRouter from 'use-react-router'
import getGridRedirectButton from 'core/elements/grid/helpers/getGridRedirectButton'
import { IClusterEditPageTabs } from 'app/plugins/infrastructure/components/clusters/model'
import StringMultiDropdownFilter from 'core/elements/grid/filters/StringMultiDropdownFilter'
import { BadgeVariant } from 'core/elements/badge/Badge'
import FontAwesomeIcon from 'core/components/FontAwesomeIcon'
import { makeStyles } from '@material-ui/styles'
import Theme from 'core/themes/model'
import Text from 'core/elements/Text'

const defaultParamsClusterSelector = makeParamsClustersSelector()

type ModelDataKey = DataKeys.Clusters
type SelectorModel = ArrayElement<ReturnType<typeof clustersSelector>>
type ActionParams = InferActionParams<typeof listClusters>
// @fixme using a type here because of https://github.com/microsoft/TypeScript/issues/15300
type Params = ActionParams & {
  masterNodeClusters?: boolean
  masterlessClusters?: boolean
  hasControlPanel?: boolean
  healthyClusters?: boolean
  prometheusClusters?: boolean
}

interface StyleProps {
  variant?: string
}

const useStyles = makeStyles<Theme, StyleProps>((theme: Theme) => ({
  nodesCell: {
    display: 'inline-grid',
    gap: 8,
    gridAutoFlow: 'column',
    alignItems: 'center',
  },
  nodesCircle: {
    color: ({ variant }) => theme.components.badge[variant]?.color,
  },
}))

// All other phases not listed here to be Grey
const healthMap = {
  Healthy: 'success',
  Unhealthy: 'error',
  'Partially Healthy': 'warning',
}

const getHealthStatus = (phase) => {
  const status: BadgeVariant = healthMap[phase] || 'unknown'
  return { variant: status }
}

export const getAddonStatus = (addon) => {
  const status: BadgeVariant = !!addon.healthy
    ? 'success'
    : addon?.phase === 'Installing'
    ? 'warning'
    : 'error'
  const label = !!addon.healthy
    ? 'Healthy'
    : addon?.phase === 'Installing'
    ? 'Installing'
    : 'Unhealthy'
  return { variant: status, label }
}

const NodesCell = ({ value }) => {
  const numReady = value?.filter((node) => node?.ready === 'True')?.length
  const variant = numReady === value?.length ? 'success' : 'error'
  const classes = useStyles({ variant })
  return (
    <div className={classes.nodesCell}>
      <FontAwesomeIcon className={classes.nodesCircle} size="xs" solid>
        circle
      </FontAwesomeIcon>
      <Text variant="body2">
        Ready {numReady}/{value?.length}
      </Text>
    </div>
  )
}

const columns: GridViewColumn<SelectorModel>[] = [
  {
    key: 'uuid',
    label: 'UUID',
    CellComponent: UUIDCell,
    display: false,
  } as GridViewColumn<SelectorModel, 'uuid'>,
  // Disable cell memoization since it prevents the hook within the cell to fetch the updated user prefs
  {
    key: 'name',
    label: 'Name',
    width: 'medium',
    CellComponent: ClusterNameCell,
    memoizeCell: false,
  },
  {
    key: 'healthStatusLabel',
    label: 'Overall Status',
    CellComponent: createGridStatusCell({
      dataFn: getHealthStatus,
    }),
  },
  {
    key: 'cloudProviderType',
    label: 'Infrastructure Type',
    CellComponent: InfrastructureTypeCell,
  } as GridViewColumn<SelectorModel, 'cloudProviderType'>,
  { key: 'usage', label: 'Resource Utilization', CellComponent: StatsCell } as GridViewColumn<
    SelectorModel,
    'usage'
  >,
  {
    key: 'nodes',
    label: 'Nodes',
    CellComponent: NodesCell,
  },
  {
    key: 'kubevirtAddon',
    label: 'KubeVirt Addon Status',
    CellComponent: createGridStatusCell({
      dataFn: getAddonStatus,
    }),
  },
  { key: 'version', label: 'K8s Version', CellComponent: K8sVersionCell },
  { key: 'kubevirtAddon.version', label: 'KubeVirt Version' },
]

const searchTargets = ['name', 'uuid']

const usePrefParams = createUsePrefParamsHook<Params & TablePrefsParams>(
  'KubevirtClusters',
  listTablePrefs,
)

const oneSecond = 1000

const defaultParams: Params = {}

const dropdownFilters = [
  {
    key: 'healthStatusLabel',
    label: 'Overall Status',
    FilterComponent: StringMultiDropdownFilter,
    filterComponentProps: {
      label: 'Overall Status',
    },
    filterComponentOptionsPropName: 'dropdownOptions',
    getOptionsFn: (items) => {
      const options = items?.map((item) => ({
        label: item?.healthStatusLabel,
        value: item?.healthStatusLabel,
      }))
      return uniq(options)
    },
    equalityComparerFn: (item, value) => {
      const hasSome = value?.some((status) => {
        return item?.healthStatusLabel === status
      })
      return hasSome
    },
  },
  {
    key: 'kubevirtStatus',
    label: 'KubeVirt Status',
    FilterComponent: StringMultiDropdownFilter,
    filterComponentProps: {
      label: 'KubeVirt Status',
    },
    filterComponentOptionsPropName: 'dropdownOptions',
    getOptionsFn: (items) => {
      const options = items?.map((item) => {
        const addon = item?.kubevirtAddon
        if (!addon) {
          return { label: 'Unknown', value: undefined }
        }
        const status = addon?.healthy
          ? 'Healthy'
          : addon?.phase === 'Installing'
          ? 'Installing'
          : 'Unhealthy'
        return {
          label: status,
          value: status,
        }
      })
      return uniq(options)
    },
    equalityComparerFn: (item, value) => {
      const hasSome = value?.some((status) => {
        return (
          (item?.kubevirtAddon?.healthy && status === 'Healthy') ||
          (item?.kubevirtAddon?.phase === 'Installing' && status === 'Installing') ||
          (!item?.kubevirtAddon?.healthy &&
            item?.kubevirtAddon?.phase !== 'Installing' &&
            status === 'Unhealthy')
        )
      })
      return hasSome
    },
  },
]

export default function KubevirtClustersListPage() {
  const { history } = useReactRouter()
  const { params, getParamsUpdater } = usePrefParams(defaultParams)
  const { message, loading, reload } = useListAction(listClusters, {
    params,
  })
  const data = useSelectorWithParams(defaultParamsClusterSelector, {
    ...params,
    kubevirtClusters: true,
  })

  const [selectedCluster, setSelectedCluster] = useState(null)
  const [showDownloadKubeconfigModal, setShowDownloadKubeconfigModal] = useState(false)
  const [showForceScaleMastersDialog, setShowForceScaleMastersDialog] = useState(false)
  const [showForceScaleWorkersDialog, setShowForceScaleWorkersDialog] = useState(false)
  const [showDefaultClusterDialog, setShowDefaultClusterDialog] = useState(false)
  const [showDeleteDialog, setShowDeleteDialog] = useState(false)

  const batchActions: GridBatchActionSpec<SelectorModel>[] = [
    {
      icon: 'edit',
      label: 'Edit',
      BatchActionButton: getGridRedirectButton<SelectorModel>(({ uuid }) =>
        routes.cluster.managed.qbert.edit.path({
          id: uuid,
          tab: IClusterEditPageTabs.General,
        }),
      ),
    },
    {
      label: 'Kubeconfig Download',
      icon: 'arrow-circle-down',
      handleAction: (clusters) => {
        setSelectedCluster(clusters[0] ?? null)
        setShowDownloadKubeconfigModal(true)
      },
      refreshAfterSuccess: false,
    },
    {
      cond: (clusters) => {
        const cluster = clusters[0] ?? null
        if (!isAdmin()) return false
        return canScaleMasters([cluster])
      },
      icon: 'expand-alt',
      label: 'Scale Masters',
      handleAction: (clusters) => {
        setSelectedCluster(clusters[0] ?? null)
        setShowForceScaleMastersDialog(true)
      },
      refreshAfterSuccess: false,
    },
    {
      cond: (clusters) => {
        const cluster = clusters[0] ?? null
        if (!isAdmin()) return false
        return canScaleWorkers([cluster])
      },
      icon: 'expand-alt',
      label: 'Scale Workers',
      handleAction: (clusters) => {
        setSelectedCluster(clusters[0] ?? null)
        setShowForceScaleWorkersDialog(true)
      },
      refreshAfterSuccess: false,
    },
    {
      cond: (clusters) => {
        const cluster = clusters[0] ?? null
        if (!isAdmin()) return false
        return canUpgradeCluster([cluster])
      },
      label: 'Upgrade',
      icon: 'level-up',
      handleAction: (clusters) =>
        history.push(routes.cluster.managed.qbert.upgrade.path({ id: clusters[0]?.uuid })),
      refreshAfterSuccess: false,
    },
    {
      cond: () => isDeccoEnv(),
      icon: 'star',
      label: 'Set as Default',
      handleAction: (clusters) => {
        setSelectedCluster(clusters[0] ?? null)
        setShowDefaultClusterDialog(true)
      },
    },
    {
      cond: (clusters) => {
        const cluster = clusters[0] ?? null
        if (!isAdmin()) return false
        return canDeleteCluster([cluster])
      },
      label: 'Delete',
      icon: 'trash-alt',
      handleAction: (clusters) => {
        setSelectedCluster(clusters[0] ?? null)
        setShowDeleteDialog(true)
      },
      refreshAfterSuccess: true,
    },
  ]

  const rowMenuItems: Array<GridRowMenuItemSpec<SelectorModel>> = [
    {
      label: 'Edit',
      icon: 'edit',
      handleClick: (cluster) =>
        history.push(routes.cluster.managed.qbert.edit.path({ id: cluster?.uuid })),
      refreshAfterSuccess: false,
      hideIfDisabled: true,
    },
    {
      label: 'Kubeconfig Download',
      icon: 'arrow-circle-down',
      handleClick: (cluster) => {
        setSelectedCluster(cluster)
        setShowDownloadKubeconfigModal(true)
      },
      refreshAfterSuccess: false,
      hideIfDisabled: true,
    },
    {
      cond: (cluster) => isAdmin() && canScaleMasters([cluster]),
      icon: 'expand-alt',
      label: 'Scale Masters',
      handleClick: (cluster) => {
        setSelectedCluster(cluster)
        setShowForceScaleMastersDialog(true)
      },
      refreshAfterSuccess: false,
      hideIfDisabled: true,
    },
    {
      cond: (cluster) => isAdmin() && canScaleWorkers([cluster]),
      icon: 'expand-alt',
      label: 'Scale Workers',
      handleClick: (cluster) => {
        setSelectedCluster(cluster)
        setShowForceScaleWorkersDialog(true)
      },
      refreshAfterSuccess: false,
      hideIfDisabled: true,
    },
    {
      cond: (cluster) => isAdmin() && canUpgradeCluster([cluster]),
      label: 'Upgrade',
      icon: 'level-up',
      handleClick: (cluster) =>
        history.push(routes.cluster.managed.qbert.upgrade.path({ id: cluster?.uuid })),
      refreshAfterSuccess: false,
      hideIfDisabled: true,
    },
    {
      cond: () => isDeccoEnv(),
      icon: 'star',
      label: 'Set as Default',
      handleClick: (cluster) => {
        setSelectedCluster(cluster)
        setShowDefaultClusterDialog(true)
      },
    },
    {
      cond: (cluster) => isAdmin() && canDeleteCluster([cluster]),
      label: 'Delete',
      icon: 'trash-alt',
      handleClick: (cluster) => {
        setSelectedCluster(cluster)
        setShowDeleteDialog(true)
      },
      refreshAfterSuccess: true,
      hideIfDisabled: true,
    },
  ]

  return (
    <>
      <DocumentMeta title="KubeVirt Clusters" />
      <PollingData hidden loading={loading} onReload={reload} refreshDuration={oneSecond * 30} />
      {showDownloadKubeconfigModal && (
        <KubeconfigDownloadModal
          open={true}
          cluster={selectedCluster}
          onClose={() => setShowDownloadKubeconfigModal(false)}
        />
      )}
      {showDefaultClusterDialog && (
        <SetAsDefaultDialog
          rows={[selectedCluster]}
          defaultsKey={UserPreferences.Cluster}
          type="cluster"
          onClose={() => setShowDefaultClusterDialog(false)}
        />
      )}
      {showDeleteDialog && (
        <ClusterDeleteDialog
          onClose={() => setShowDeleteDialog(false)}
          rows={[selectedCluster]}
          onSuccess={() => setShowDeleteDialog(false)}
        />
      )}
      {showForceScaleMastersDialog && (
        <ForceScaleClusterDialog
          rows={[selectedCluster]}
          onClose={() => setShowForceScaleMastersDialog(false)}
          dialogRoute={() =>
            routes.cluster.managed.qbert.scaleMasters.path({ id: selectedCluster?.uuid })
          }
          canForceScale={both(isAdmin, canForceScaleMasters)}
        />
      )}
      {showForceScaleWorkersDialog && (
        <ForceScaleClusterDialog
          rows={[selectedCluster]}
          onClose={() => setShowForceScaleWorkersDialog(false)}
          dialogRoute={() =>
            routes.cluster.managed.qbert.scaleWorkers.path({ id: selectedCluster?.uuid })
          }
          canForceScale={both(isAdmin, canForceScaleWorkers)}
        />
      )}
      <ListContainer<ModelDataKey, SelectorModel>
        dataKey={DataKeys.Clusters}
        searchTargets={searchTargets}
        uniqueIdentifier="uuid"
        loading={loading}
        loadingMessage={message}
        onRefresh={reload}
        data={data}
        columns={columns}
        getParamsUpdater={getParamsUpdater}
        rowMenuItems={rowMenuItems}
        label="KubeVirt Clusters"
        showItemsCountInLabel
        batchActions={batchActions}
        dropdownFilters={dropdownFilters}
        maxRowMenuHeight={150} // set a max row menu height with overflow scroll
        {...pick(listTablePrefs, params)}
      />
    </>
  )
}
